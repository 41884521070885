import React from "react";
import { Link, graphql, navigate } from "gatsby";
import useEventListener from "@use-it/event-listener";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Template({ data, pageContext }) {
  const { prev, next } = pageContext;
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { categories, comments, author } = frontmatter;
  useEventListener("keydown", ({ key }) => {
    key === "ArrowRight" && next && navigate(next.path);
    key === "ArrowLeft" && prev && navigate(prev.path);
  });
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="blog-post-container">
        <div className="blog-post">
          <h1 className="text-4xl leading-tight">{frontmatter.title}</h1>
          <h2 className="text-gray-800">
            {frontmatter.date}&nbsp;&nbsp; | &nbsp;&nbsp;
            <i>by</i> {author.replace(" Henderson", "")}
          </h2>
          <div
            className="content py-2"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {categories.length && (
            <div className="cats text-gray-800 italic text-sm text-center mb-6">
              Posted in:{" "}
              {categories.map(cat => (
                <Link
                  key={cat}
                  to={`/category/${cat}`}
                  className="cat-link capitalize text-teal-600"
                >
                  {cat.replace(/-/g, " ")}
                </Link>
              ))}
            </div>
          )}
          {comments && comments.length && (
            <div className="comments text-xs max-w-4xl mb-8">
              <h3 className="text-xl mt-0">
                {comments.length} Comment{comments.length === 1 ? "" : "s"}
              </h3>
              {comments.map((c, i) => (
                <div
                  key={c.wpId}
                  className={`p-2 ${i % 2 ? "bg-gray-300" : "bg-gray-100"}`}
                >
                  <div className="comment-meta">
                    <b>{c.name}</b>
                    <i className="pl-2 text-gray-700">
                      {c.date.replace(/ .*/, "")}
                    </i>
                  </div>
                  <div
                    className="comment-body"
                    dangerouslySetInnerHTML={{
                      __html: `<p>${c.html.replace(
                        /<br ?\/?>/gi,
                        "</p><p>"
                      )}</p>`,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          {(prev || next) && (
            <div className="flex my-4 text-blue-600 leading-tight">
              {prev && (
                <Link className="prev flex-1 self-start" to={prev.path}>
                  &laquo;&nbsp;{prev.title}
                </Link>
              )}
              {next && (
                <Link
                  className="next self-end flex-1 text-right"
                  to={next.path}
                >
                  {next.title}&nbsp;&raquo;
                </Link>
              )}
            </div>
          )}
          {/* <pre>{JSON.stringify({ comments }, null, 2)}</pre> */}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        author
        title
        categories
        comments {
          wpId
          name
          email
          html
          date
        }
      }
    }
  }
`;
